<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Header
          :key="keyHeaderCryo"
          @showCardInfo="$emit('showCardInfo')"
        ></Header>
        <v-card
          v-if="loading"
          tile
          class="row"
          style="width: 100%; height: 200px"
        >
          <loading-circle v-if="loading" />
        </v-card>

        <div v-else>
          <NoCard
            :showAddCard="showAddCard"
            @sucessAddCard="getCardConnectProfile"
          />
          <v-card v-if="!showAddCard" tile class="row" style="width: 100%">
            <v-tabs v-model="tab">
              <v-tab key="freeze">Freezes</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" style="width: 100%">
              <v-tab-item key="freeze">
                <v-card-text>
                  <v-data-table
                    class="table-freezes"
                    :headers="freezeHeaders"
                    :items="freezeItems"
                    :loading="freezeLoading"
                    :items-per-page="4"
                  >
                    <template v-slot:[`item.FreezeDate`]="{ item }">
                      {{ item.FreezeDate }}
                    </template>
                    <template v-slot:[`item.InventoryDate`]="{ item }">
                      {{ item.InventoryDate }}
                    </template>
                    <template v-slot:[`item.source`]="{ item }">
                      {{ item.source }}
                    </template>
                    <template v-slot:[`item.type`]="{ item }">
                      {{ item.type }}
                    </template>
                    <template v-slot:[`item.location`]="{ item }">
                      {{ item.location }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

          <v-card
            v-if="!showAddCard"
            tile
            class="row"
            style="width: 100%; margin-top: 20px"
          >
            <v-tabs v-model="tab">
              <v-tab key="billing_summary">Billing Summary</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" style="width: 100%">
              <v-tab-item key="billing_summary">
                <v-card-text>
                  <v-data-table
                    class="table-freezes"
                    :headers="billingHeaders"
                    :items="cryoPatientBillingProfile"
                    :loading="cryoPatientBillingProfileLoading"
                    :items-per-page="4"
                  >
                    <template v-slot:[`item.type`]="{ item }">
                      {{ item.type }}
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                      {{ item.price ? formatMoney(item.price) : '$0.00' }}
                    </template>

                    <template v-slot:[`item.next_billing_at`]="{ item }">
                      {{ item.next_billing_at }}
                    </template>

                    <template v-slot:[`item.cadence`]="{ item }">
                      {{ item.cadence }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                      {{ item.status }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <Eula />
  </v-container>
</template>

<script>
import LoadingCircle from '@/components/LoadingCircle.vue';
import Eula from '@/components/Eula.vue';
import Header from './Header.vue';
import NoCard from './NoCard.vue';
import _ from 'lodash';
import moment from 'moment';
import { api, auth } from '../../sharedPlugin';

export default {
  components: {
    LoadingCircle,
    Header,
    NoCard,
    Eula
  },
  data() {
    return {
      keyHeaderCryo: new Date().getTime(),
      snackbar: false,
      months: [],
      cards: [],
      years: [],
      submitting: false,
      tab: 0,
      showAddCard: false,
      next_billing_at: null,
      freezeLoading: false,
      freezeHeaders: [
        { text: 'Freeze Date', value: 'FreezeDate', sortable: false },
        { text: 'Recieve date', value: 'InventoryDate', sortable: false },
        { text: 'Source', value: 'source', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Location', value: 'location', sortable: false }
      ],
      cryoPatientBillingProfile: [],
      billingHeaders: [
        { text: 'Service', value: 'type', sortable: false },
        { text: 'Billing Amount', value: 'price', sortable: false },
        {
          text: 'Next Billing Date',
          value: 'next_billing_at',
          sortable: false
        },
        { text: 'Billing Cadence', value: 'cadence', sortable: false },
        { text: 'Status', value: 'status', sortable: false }
      ],
      invoicesItems: [],
      cryoPatientBillingProfileLoading: false,
      selectedInvoice: null,
      payInoviceModal: false,
      dialog: false,
      method: null,
      selectedInvoices: null,
      balanceLoading: false,
      balance: 0,
      loading: false,
      egg: null,
      sperm: null,
      embryo: null
    };
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  methods: {
    async getFreezes() {
      this.freezeLoading = true;
      await api.Cryo.getCryoInventory()
        .then((res) => {
          this.freezeLoading = true;

          this.freezeItems = res.map((item) => ({
            FreezeDate: item.FreezeDate
              ? moment.utc(item.FreezeDate).format('MM/DD/YYYY')
              : '',
            InventoryDate: item.InventoryDate
              ? moment.utc(item.InventoryDate).format('MM/DD/YYYY')
              : '',
            source: item.source,
            type: item.type,
            next_billing_at: item.next_billing_at
              ? moment.utc(item.next_billing_at).format('MM/DD/YYYY')
              : '',
            billingprofile: item.billingprofile,
            amount:
              item.amount && item.discount_amount
                ? `$ ${(
                    parseFloat(item.amount) - parseFloat(item.discount_amount)
                  ).toFixed(2)}`
                : item.amount
                ? `$ ${item.amount}`
                : `$ 0.00`,
            active_pbp: item.active_pbp,
            location: item.location
          }));

          let embryo = this.freezeItems.filter((item) => {
            return item.type === 'Embryo';
          });

          this.embryo = embryo && embryo.length > 0 ? embryo[0] : null;
          let sperm = this.freezeItems.filter((item) => {
            return item.type === 'Sperm';
          });

          this.sperm = sperm && sperm.length > 0 ? sperm[0] : null;

          let egg = this.freezeItems.filter((item) => {
            return item.type === 'Egg';
          });

          this.egg = egg && egg.length > 0 ? egg[0] : null;
        })
        .finally(() => (this.freezeLoading = false));
    },
    reduceObject(value, key) {
      return value
        .map((item) => {
          return parseFloat(item[key]);
        })
        .reduce(
          (previous_value, current_value) => previous_value + current_value
        );
    },
    formatMoney(amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      if (!amount) return '$0.00';
      return formatter.format(amount);
    },
    async getCryoPatientBillingProfile() {
      this.cryoPatientBillingProfileLoading = true;
      await api.Cryo.getCryoPatientBillingProfile(this.user.patientId)
        .then((res) => {
          this.cryoPatientBillingProfile = res.map((item) => ({
            status: item.close_reason ? item.close_reason : 'Active',
            next_billing_at: item.next_billing_at
              ? moment.utc(item.next_billing_at).format('MM/DD/YYYY')
              : '',
            price: item.amount_final,
            type: item.type,
            cadence: item.cadence
          }));
        })
        .finally(() => (this.cryoPatientBillingProfileLoading = false));
    },
    getCardConnectProfile() {
      if (this.user.first_cryo_login === null) {
        var _user = this.user;
        api.Cryo.addFirstLoginCryo()
          .then(async (res) => {
            _user.first_cryo_login = new Date();
            this.$store.commit('setUser', { user: _user, save: true });
          })
          .catch((err) => alert(err))
          .finally(() => {});
      }
    },
    verifiyFirst_Cryo_Login() {
      this.loading = true;
      this.cards = [];
      api.CardConnect.getCardConnectProfile()
        .then(async (res) => {
          if (res.length > 0) {
            this.showAddCard = false;

            const cardProfiles = [...res];
            cardProfiles.forEach((cardProfile) => {
              this.cards.push({
                ...cardProfile,
                expiry: cardProfile.expires,
                account:
                  cardProfile.token.substr(1, 2) +
                  'XX XXXX XXXX ' +
                  cardProfile.last_four,
                cardType: cardProfile.description,
                lastfour: cardProfile.last_four
              });
            });
          } else {
            this.showAddCard = true;
          }
        })
        .catch((err) => alert(err))
        .finally(() => {
          this.loading = false;
          this.keyHeaderCryo = new Date().getTime();
        });
    }
  },
  created() {
    this.getCardConnectProfile();
    this.getFreezes();
    this.getCryoPatientBillingProfile();
    this.verifiyFirst_Cryo_Login();
  }
};
</script>

<style>
.table-freezes .v-data-table__wrapper table tbody tr td {
  height: 30px !important;
  font-size: 0.8rem !important;
}
.table-freezes .v-data-table__wrapper .v-data-table-header tr th {
  color: #000000 !important;
}
.v-data-table.row-height-50 td {
  height: 18px !important;
}
</style>
